<template>
  <fragment>
    <app-header title="Learner Quality Score" />
    <validation-observer as="div" class="container mt-4" v-if="this.course.id">
      <h2>{{course.name}}</h2>
      <div class="row">
        <div class="col">
          <p>By {{course.provider}}</p>
        </div>
        <div class="col-auto" v-if="this.course.date">
          <p class="text-end">Course Date: {{course.date | date({ parse: 'YYYY-MM-DD'})}}</p>
        </div>
      </div>
      <div class="card">
          <div class="card-body" v-if="!questionnaire.id">
             <div class="p-3">
                <h2>Please complete your Learner Quality Feedback</h2>
                <small class="text-muted">
                  You have been invited to provide anonymous structured feedback about the educational course or content you have recently completed. The purpose of this feedback is to assess the quality of the education being provided to objectively help you and your colleagues identify quality educational content.
                </small>
             </div>
              <div v-for="questionTemplate in questionnaireQuestions" :key="questionTemplate.id" class="p-3">
                  <question-item :item="questionTemplate" @change="updateScore" :disabled="disabled"/>
              </div>

              <validation-provider tag="div" class="m-3"  name="feedback">
                <label class="form-label h4">Additional Feedback</label>
                <c-textarea v-model="feedback" rows="2" :disabled="disabled"></c-textarea>
                <c-help-block/>
                <span class="form-text">If you have any questions regarding the questionnaire or would like to provide further feedback please contact us <a href="https://medcpd.com/contact/" class="link-primary" target="blank"><u>here</u></a>.</span>
              </validation-provider>

              <div class="py-3">
                  <div class="col-auto text-end">
                      <c-button variant="primary" @click="save" validate :disabled="disabled">Submit Feedback</c-button>
                  </div>
              </div>
          </div>
          <div class="car-body" v-else>
            <p class="text-center p-5">You have already submitted your feedback for this course</p>
          </div>
      </div>
    </validation-observer>

  </fragment>
</template>

<script>
import { get, call } from 'vuex-pathify'
import QuestionItem from '@/components/questionnaires/QuestionItem.vue'
import { toast } from '@chameleon/chameleon-vue'

export default {
  data () {
    return {
      feedback: null,
      disabled: false
    }
  },

  components: {
    QuestionItem,
  },

  computed: {
    questionnaireQuestions: get('questionnaireQuestions/questionnaireQuestions'),
    course: get('courses/course'),
    questionnaire: get('questionnaires/questionnaire')
  },

  methods: {
    getQuestionnaireQuestions: call('questionnaireQuestions/getQuestionnaireQuestions'),
    getQuestionnaire: call('questionnaires/getQuestionnaire'),
    storeQuestionnaireResponse: call('questionnaires/storeQuestionnaireResponse'),
    getCourseByUuid: call('courses/getCourseByUuid'),

    updateScore (item) {
      const preItem = this.questionnaireQuestions.find(({ id }) => id === item.id)
      preItem.score = item.score
    },

    async save () {
      await this.storeQuestionnaireResponse({
        response: this.questionnaireQuestions.map((item) => ({
          question_id: item.id,
          score: item.score,
        })),
        course_id: this.course.id,
        feedback: this.feedback
      })
      this.disabled = true
      toast('Submitted successfully', 'success')
    }
  },

  async created () {
    await this.getQuestionnaireQuestions()
    try {
      await this.getCourseByUuid(this.$route.params.courseUuid)
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast('Course is not found', 'danger')
        this.$router.push({ name: 'dashboard' })
      } else {
        throw error
      }
    }
    await this.getQuestionnaire({
      course_id: this.course.id,
    })
  }
}
</script>

<style>
  .fa-info-circle {
    color: #005488;
  }
  .vue-slider-mark-label {
    font-size: 10px;
    margin-top: 5px;
  }
</style>
