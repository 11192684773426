<template>
  <fragment>
    <h4>{{item.category}}
      <i class="far fa-info-circle" :aria-expanded="ariaExpandedClass"
      :aria-controls="`description-${item.id}`"
      @click="toggleCollapsed"></i>
    </h4>
     <div class="row">
      <div class="col-lg-6">
          <span>{{item.title}}</span>
          <div :id="`description-${item.id}`"
            :aria-labelledby="`description-${item.id}`"
            class="collapse"
            ref="collapse">
            <small class="text-muted">{{item.description}}</small>
          </div>
      </div>
      <validation-provider tag="div" class="col-lg-6" :name="`score[${item.id}]`" rules="required">
          <c-invalid-wrapper role="group">
              <vue-slider
              :adsorb="true"
              :interval="1"
              :tooltip="'none'"
              :data="data"
              v-model="value"
              @change="updateScore"
              :disabled="disabled"
              :marks="true">
              </vue-slider>
          </c-invalid-wrapper><!-- /.input-group -->
          <c-help-block class="mt-5"/>
      </validation-provider>
    </div>
  </fragment>
</template>

<script>
import { cloneDeep } from 'lodash'
import { Collapse } from 'bootstrap'

export default {
  data () {
    return {
      value: null,
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      collapsed: true,
      collapse: null
    }
  },
  props: {
    item: Object,
    disabled: Boolean
  },
  computed: {
    ariaExpandedClass () {
      return this.collapsed ? 'false' : 'true'
    }
  },
  mounted () {
    this.collapse = new Collapse(this.$refs.collapse, { toggle: false })
  },
  methods: {
    updateScore (value) {
      const itemObj = cloneDeep(this.item)
      itemObj.score = value
      this.$emit('change', itemObj)
    },
    toggleCollapsed () {
      this.collapse.toggle()
      this.collapsed = !this.collapsed
    },
  }
}
</script>
